<template>
  <v-dialog v-model='dialog' persistent width='600px'>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn small text @click.stop="openDialog">
        <v-icon
          color='black'
          left
          small
        >
          mdi-form-textbox
        </v-icon>
        {{ $t('reports.titles.printLimit') }}
      </v-btn>
    </template>
    <v-card class='pa-4'>
      <v-card-title class='headline mb-4'>
        {{ $t('reports.titles.printLimitTitle') }}
      </v-card-title>
      <v-card-text>
        <v-overlay :value='loading' absolute color='white'>
          <v-progress-circular color='secondary' indeterminate />
        </v-overlay>
        <v-row>
          <v-col cols='8'>
            <v-form ref='form' v-model='valid'>
              <v-text-field
                :disabled="!switchValue"
                color="secondary"
                type="number"
                outlined
                dense
                v-model='printLimit'
                :label="$t('reports.titles.printLimit')"
                :rules="switchValue ? checkPrintLimit(item.printLimit) : []"
              />
            </v-form>
            <v-switch
              color="secondary"
              :label="switchValue ? $t('reports.fields.disablePrintLimit') : $t('reports.fields.activePrintLimit')"
              v-model="switchValue"
              @change="onSwitchChange"
            ></v-switch>
          </v-col>
          <v-col cols="12" class="d-flex">
            <p class="mr-4"><strong>{{ $t('reports.titles.oldPrintLimit') }}: </strong>{{ item.printLimit === -1 ? '&infin;' : item.printLimit }}</p>
            <p class="mr-4"><strong>{{ $t('reports.titles.newPrintLimit') }}: </strong>{{ printLimit === -1 ? '&infin;' : printLimit }}</p>
            <p v-if="(printLimit > -1 && item.printLimit > -1)  && switchValue" class="mr-4"><strong>{{ $t('reports.titles.printDifference') }}: </strong>
              <span :class="[{ 'green--text': valid, 'red--text': !valid }]">
              &plusmn; {{ printLimit - (item.printLimit) }}
              </span>
            </p>
            <p v-else-if="(printLimit > -1 && item.printLimit === -1) && switchValue" class="mr-4"><strong>{{ $t('reports.titles.printDifference') }}: </strong>
              <span :class="[{ 'green--text': valid, 'red--text': !valid }]">
              &plusmn; {{ printLimit }}
              </span>
            </p>
            <p v-else class="mr-4"><strong>{{ $t('reports.titles.printDifference') }}: </strong>
              <span>
                &infin;
              </span>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled='loading'
          color='gray'
          small
          text
          @click="closeDialog"
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <v-btn
          :disabled='loading || !valid'
          color='secondary'
          small
          text
          @click="setPrintLimit"
        >
          {{ $t('general.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import _ from 'lodash'
  import i18n from '@/plugins/i18n'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'LimitPrintDialog',

    props: {
      item: {
        type: Object,
        require: true,
      },
    },

    data() {
      return {
        dialog: false,
        valid: false,
        printLimit: null,
        loading: false,
        switchValue: false
      }
    },

    computed: {
      ...mapGetters({
        checkPrintLimit: 'general/checkPrintLimit',
      }),
    },

    methods: {
      ...mapActions({
        setQrPrintLimit: 'reports/setQrPrintLimit'
      }),
      onSwitchChange() {
        if (!this.switchValue) {
          this.printLimit = -1;
        } else {
          if (this.item.printLimit && this.item.printLimit !== -1) {
            this.printLimit = this.item.printLimit
          } else {
            this.printLimit = 0;
          }
          this.$refs.form.resetValidation()
        }
      },
      async setPrintLimit() {
        this.loading = true
        this.dialog = true

        await this.setQrPrintLimit([this.$toast,
          {
            id: this.item.id,
            printLimit: this.printLimit
          }
        ]).finally(() => {
          this.loading = false
          this.dialog = false
          this.printLimit = null
          this.$emit('close:options', false)
        })
      },
      openDialog() {
        this.switchValue = this.item.printLimit !== -1;
        this.printLimit = this.switchValue ? this.item.printLimit : 0;
        this.$emit('close:options', false);
        this.dialog = true;

        if (this.$refs.form)
          this.$refs.form.resetValidation()
      },
      closeDialog() {
        this.loading = false
        this.dialog = false
        this.printLimit = null
        this.$emit('close:options', false)
      },
    },
  }
</script>

<style scoped>

</style>
