<template>
  <v-container ref="content" fluid>
    <v-list-item class="mb-3" three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">{{ $t('reports.titles.qrReport') }}</p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      v-model="selectedItems"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
      :headers="headers()"
      :items="qrList"
      :loading="searchRecords || loadingQrRecords"
      :loading-text="$t('general.notification.loadData')"
      :no-data-text="$t('general.ui.not_data_found')"
      :no-results-text="$t('general.ui.not_search_found')"
      :options.sync="options"
      :server-items-length="totalFromServer"
      :show-select="showCheckBox"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
              onkeypress="return (event.charCode != 34)"
              v-on:keyup.86="replace"
              v-on:keyup.ctrl.86="replace"
              dense
              filled
              rounded
              color="secondary"
              v-model="search"
              :clearable="true"
              @keypress.enter="searchForText"
              @click:clear="searchAll"
              @click:append="searchForText"
              append-icon="mdi-magnify"
              :label="$t('general.titles.filter')"
              single-line
              hide-details
              :disabled="!!(source && source.token)"
            />
          </v-col>
          <!-- <v-col lg="2" md="2" sm="6" xl="2">
            <v-btn
              :disabled="
                !!(source && source.token) || !qrList.length > 0
              "
              :elevation="0"
              class="mr-1"
              color="#d8d8d896"
              small
              style="border-radius: 1.5em"
              tile
              @click="checkBoxShow"
            >
              <span v-if="!showCheckBox">
                <v-icon color="#6D6D6D" left small
                  >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span>{{ $t("general.buttons.checksBoxEnable") }}</span>
              </span>
              <span v-else>
                <v-icon color="#6D6D6D" left small
                  >mdi-checkbox-multiple-marked</v-icon
                >
                <span>{{ $t("general.buttons.checksBoxDisable") }}</span>
              </span>
            </v-btn>
          </v-col> -->
          <v-col lg="5" md="5" sm="6" xl="5">
            <v-toolbar color="white" flat>
              <v-spacer></v-spacer>
              <v-badge
                :content="cantFilters"
                :value="cantFilters"
                bordered
                color="secondary"
                left
                overlap
              >
                <v-btn
                  :disabled="
                    !!(source && source.token)
                  "
                  :elevation="0"
                  color="#d8d8d896"
                  small
                  :style="updateStylesMenuButtons
                    ? 'border-radius: 1.5em 0 0 1.5em'
                    : 'border-radius: 1.5em'
                  "
                  tile
                  @click="openFilters"
                >
                  <v-icon color="#6D6D6D" left small>mdi-filter-variant</v-icon>
                  {{ $t("general.buttons.filter") }}
                </v-btn>
              </v-badge>
              <!--<v-menu
                :absolute="false"
                :open-on-hover="false"
                :close-on-click="true"
                :close-on-content-click="false"
                :offset-y="true"
                v-model="menu"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="#d8d8d896"
                    @click="menu = !menu"
                    :disabled="!!(source && source.token)"
                  >
                    <v-icon small color="#6D6D6D" left>mdi-cog</v-icon>
                    <span>{{ $t("general.buttons.options") }}</span>
                  </v-btn>
                </template>
                <v-list class="pa-3">
                  <v-list-item dense class="d-block py-2">
                    <div class="d-block">
                      &lt;!&ndash; Generate Multiple Reports&ndash;&gt;
                      &lt;!&ndash; <GenerateNewTraceabilityReportTable v-on:closeMenu="(menu = $event)" :lots-ids='lotsIds' />&ndash;&gt;
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>-->
              <v-btn
                :elevation="0"
                color="#d8d8d896"
                class="ma-1"
                small
                style="border-radius: 0 1.5em 1.5em 0"
                tile
                :to="{ name: 'traceability-report' }"
                v-if="checkAdmin() || check([{ domain: 'Traceability', permisions: ['Write'] }])"
              >
                <v-icon color="#6D6D6D" left small>mdi-plus </v-icon>
                {{ $t("general.buttons.new") }}
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          v-if="filtersChips && filtersChips.length > 0"
          class="mb-3"
          flat
          style="background-color: #f4f2f2; border-radius: 0.3em"
        >
          <v-row style="display: contents">
            <v-col class="d-flex" cols="12">
              <v-chip-group active-class="neuter--text">
                <v-chip
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  :disabled="!!(source && source.token)"
                  close
                  color="#FFFFFF"
                  small
                  @click:close="cleanFilter(chip)"
                >
                  <v-avatar left>
                    <v-icon small>mdi-filter-variant</v-icon>
                  </v-avatar>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>
      <template #item.statusLine="{ item }">
        <div class="dot-validated" v-if="item.status === 'VALIDATED'">
        </div>
        <div class="dot-pending" v-if="item.status === 'PENDING'">
        </div>
        <div class="dot-validating" v-if="item.status === 'VALIDATING'">
        </div>
        <div class="dot-errored" v-if="item.status === 'ERRORED'">
        </div>
      </template>
      <template #item.status="{ item }">
        <v-chip small v-if="item.status === 'VALIDATED'">{{ $t('records.filters.status.validated') }}</v-chip>
        <v-chip small v-if="item.status === 'PENDING'">{{ $t('records.filters.status.pending') }}</v-chip>
        <v-chip small v-if="item.status === 'VALIDATING'">{{ $t('records.filters.status.validating') }}</v-chip>
        <v-chip small v-if="item.status === 'ERRORED'">{{ $t('records.filters.status.errored') }}</v-chip>
      </template>
      <template #item.reportType="{ item }">
        <v-chip small>
          {{ item.reportType }}
        </v-chip>
      </template>
      <template #item.printLimit="{ item }">
        <v-tooltip bottom v-if="disableFlowFunctions(item)" max-width="200" color="black">
          <template v-slot:activator="{ on }">
            <v-chip  disabled v-on="on" class="amount__chip" small >
              <p class="ma-0 pa-0" style="font-weight: bold; font-size: 18px">
                &infin;
              </p>
            </v-chip>
          </template>
          <span>{{ $t("reports.notification.printLimitInfo") }}</span>
        </v-tooltip>
        <v-chip class="amount__chip" small disabled v-else-if="item.printLimit === -1 && !ifPresentIntegration('IG')">
          <p class="ma-0 pa-0" style="font-weight: bold; font-size: 18px">
            &infin;
          </p>
        </v-chip>
        <v-chip class="amount__chip" small v-else>
          {{ item.printLimit }}
        </v-chip>
      </template>
      <template #item.availablePrint="{ item }">
        <v-chip class="amount__chip" small disabled v-if="item.printLimit === -1">
          <p class="ma-0 pa-0" style="font-weight: bold; font-size: 18px">
            &infin;
          </p>
        </v-chip>
        <v-chip class="amount__chip" small v-else>
          {{ item.availablePrint }}
        </v-chip>
      </template>
      <template #item.reportDate="{ item }">
        {{ getDateFormat(item.reportDate) }}
      </template>
      <template #item.active="{ item }">
        <v-icon small class="mr-4" color="secondary" v-if="item.active">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon small class="mr-4" color="neuter" v-if="!item.active">
          mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              class="mr-2"
              @click="getDetails(item)"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <span>
            {{ $t("general.buttons.show") }}
            <span style="font-size: 12px; color: #aaaaaa">
              {{ $t("menu.records") }}
            </span>
          </span>
        </v-tooltip>
        <span v-if="ifPresentIntegration('QR_SITE')">
          <!--   activate or deactivate     -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="activeOrDesactive(item)"
                v-show="!loading[item.id]"
                :disabled="!item.url || disableFlowFunctions(item)"
              >
                mdi-checkbox-marked-circle-outline
              </v-icon>
              <v-progress-circular
                v-show="loading[item.id]" size="12" class="mr-2" width="2" indeterminate />
            </template>
            <span>
              {{ item.active ? $t("general.buttons.desactivate") : $t("general.buttons.activate") }}
              <span style="font-size: 12px; color: #aaaaaa">
                {{ $t("menu.records") }}
              </span>
            </span>
          </v-tooltip>

          <!--   print     -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="neuter"
                small
                class="mr-2"
                @click="print(item)"
                :disabled=" item.printLimit === 0 || !item.url || disableFlowFunctions(item)"
              >
                mdi-printer-outline
              </v-icon>
            </template>
            <span>
              {{ $t("general.buttons.print") }}
              <span style="font-size: 12px; color: #aaaaaa">
                {{ $t("menu.records") }}
              </span>
            </span>
          </v-tooltip>
        </span>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                v-if="checkAdmin() ||
                      check([{ domain: 'Traceability', permisions: ['Delete'] }])"
                color="neuter"
                small
                @click="deleteItemDialog(item)"
              >
                mdi-trash-can-outline
              </v-icon>
            </template>
            <span>
                    {{ $t('general.buttons.delete') }}
                    <span style="font-size: 12px; color: #aaaaaa">
                      {{ $t('menu.records') }}
                    </span>
                  </span>
          </v-tooltip>


        <span v-if="ifPresentIntegration('QR_SITE')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on:tooltip }">
              <v-menu
                bottom
                left
                v-model='moreOptions[item.id]'
              >
                <template v-slot:activator='{ on:menu }'>
                  <v-icon
                    v-on='{...tooltip, ...menu}'
                    class='mx-1'
                    color="neuter"
                    size='20'
                    :disabled="!item.url"
                    @click='$set(moreOptions, item.id, !moreOptions[item.id])'
                  >
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <v-list elevation='0'>
                  <v-list-item>
                    <!--  DIALOG PRINT LIMIT  -->
                    <LimitPrintDialog @close:options="$set(moreOptions, item.id, $event)" :item="item" />
                  </v-list-item>
                  <v-list-item>
                    <!--   copy or download qr url     -->
                    <v-btn small
                           text
                           @click="openQrCodeDialog(item)"
                           :disabled="!item.url || disableFlowFunctions(item)"
                    >
                      <v-icon
                        color='black'
                        left
                        small
                      >
                        mdi-qrcode
                      </v-icon>
                      {{ $t("general.buttons.qrcode") }}
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <!--   open on new tab     -->
                    <v-btn small
                           text
                           :disabled="!item.url || disableFlowFunctions(item)"
                           :href="item.url" target="_blank"
                    >
                      <v-icon
                        color='black'
                        left
                        small
                      >
                        mdi-open-in-new
                      </v-icon>
                      {{ $t("general.buttons.externalLink") }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <span>
              {{ $t('general.buttons.options') }}
              <span style="font-size: 12px; color: #aaaaaa">
                {{ $t('menu.records') }}
              </span>
            </span>
          </v-tooltip>
        </span>
      </template>
    </v-data-table>

    <QrCodeDialog @close:dialog="closeQrCodeDialog()" :dialog="qrCodeDialog" :url="qrCodeUrl" />


    <!-- Dialog Delete-->
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn small text color="secondary" @click="deleteItem">
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from "@/plugins/i18n"
  import { mapActions, mapGetters } from "vuex"
  import moment from "moment"
  import _ from "lodash"
  import "jspdf-autotable"
  import LanguajeService from "../../../services/LanguajeService"
  import axios from "axios"
  import UserOptions from "../../../components/UserOptions/IndexComponent.vue"
  import { generalMixin } from "@/mixins/general.mixin"
  import GenerateNewTraceabilityReportTable from "@/module/reports/components/GenerateNewTraceabilityReportTable.vue"
  import DownloadErrorsComponent from "@/module/records/components/DownloadErrorsComponent.vue"
  import EvidenceDialogComponent from "@/module/records/components/EvidenceDialogComponent.vue"
  import QrCodeDialog from "@/module/reports/traceability/QrCodeDialog.vue";
  import LimitPrintDialog from "@/module/reports/traceability/LimitPrintDialog.vue";
  import PermisionService from '@/services/PermisionService'

  const FILTER_VALUES = [
    "isActive",
    "lots",
    "products",
    "reportType",
    "status"
  ]

  export default {
    components: {
      LimitPrintDialog,
      QrCodeDialog,
      EvidenceDialogComponent,
      DownloadErrorsComponent,
      GenerateNewTraceabilityReportTable,
      UserOptions,
    },
    mixins: [generalMixin],
    data: () => ({
      title: 'Información',
      dialogText: 'Añada un texto a este dialogo',
      searchRecords: false,
      search: "",
      menu: false,
      //portrait
      //landscape
      doc: "",
      evidenceListUrl: [],
      documentListUrl: [],
      indexEvidence: 0,
      indexDocument: 0,
      sortBy: "record_date_time",
      sortDesc: true,
      profile: null,
      dialogDetails: false,
      // FILTERs
      lote: null,
      product: null,
      showDialog: false,
      // config
      orientation: null,
      documentPresent: true,
      evidencePresent: true,
      exportLoading: false,
      message: i18n.t("general.notification.loadData"),
      selectedItems: [],
      showCheckBox: false,
      options: {},
      loadingQrRecords: false,
      loading: {},
      qrCodeDialog: false,
      qrCodeUrl: null,
      moreOptions: {},
      pageableDTO: {
        page:0,
        size:10,
        sortBy: "id",
        direction: "desc"
      },
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200, 500],
      },
      reports: [
        {
          type: "rastreability",
          title: i18n.t("reports.titles.traceability-report"),
        },
        {
          type: "recall",
          title: i18n.t("reports.titles.recall-report"),
        },
      ],
    }),

    watch: {
      options: {
        async handler() {
          this.optionsTable = this.options
          const direction =
            this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? "desc"
              : "asc"

          this.filters.pageableDTO = {
            page: this.options.page > 0 ? this.options.page - 1 : 0,
            size:
              this.options.itemsPerPage > -1
                ? this.options.itemsPerPage
                : this.totalFromServer,
            sortBy: "id",
            direction: direction,
          }

          await this.fetchQrRecords()
        },
        deep: true,
      },
    },

    computed: {
      ...mapGetters({
        qrList: "reports/qrList",
        totalFromServer: "reports/totalQRFromServer",
        //----ui-----------------------------------------
        ifPresentIntegration: "integration/IS_PRESENT",
        config: "general/config",
      }),
      updateStylesMenuButtons() {
        return this.checkAdmin() || this.check([{ domain: 'Traceability', permisions: ['Write'] }]);
      },
      disableFlowFunctions() {
        return (item) => { return item.printLimit === -1 && this.ifPresentIntegration('IG') }
      },
      source: {
        get() {
          return this.$store.getters["records/source"]
        },
        set(val) {
          this.$store.commit("records/SET_SOURCE", val)
        },
      },
      request: {
        get() {
          return this.$store.getters["records/request"]
        },
        set(val) {
          this.$store.commit("records/SET_REQUEST", val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters["ui/GET_DRAWER"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_DRAWER", val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters["ui/GET_COUNT_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_COUNT_FILTERS", val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters["ui/GET_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_FILTERS", val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters["ui/GET_OPTIONS_TABLE"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_OPTIONS_TABLE", val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters["ui/GET_OBJECTS_TO_FILTERS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_OBJECTS_TO_FILTERS", val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters["ui/GET_FILTERS_CHIPS"]
        },
        set(val) {
          this.$store.dispatch("ui/SET_FILTERS_CHIPS", val)
        },
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      qrRecords: {
        get() {
          return this.$store.getters["reports/qrRecords"]
        },
        set(val) {
          this.$store.commit("reports/SET_QR_RECORDS", val)
        },
      },
    },

    async created() {
      this.$store.commit('ui/SET_FILTERS_SORT', []);

      await this.initialize()
    },

    async mounted() {
      this.type = "traceability-qr"
      await this.fetchQrRecords()

      this.$root.$on('setFiltersQRTraceability', (page) => {
        this.closeFilterDialog(page)
      })

      this.$root.$on('getResults', () => {
        this.closeFilterDialog()
      })
    },

    methods: {
      ...mapActions({
        fetchTraceabilityQRData: "reports/fetchTraceabilityQRData",
        activeOrDesactiveQr: "reports/activeOrDesactiveQr",
        deleteReportTraceability: "reports/deleteReportTraceability"
      }),
      // Show Delete dialog for delete Record
      deleteItemDialog(item) {
        this.deletingItem = item
        this.dialogShow({
          title: i18n.t('general.titles.alert'),
          dialogText: i18n.t('reports.notification.deleteTraceabilityReport'),
        })
      },
      // Delete Action
      async deleteItem() {
        this.dialogClose()

        await this.deleteReportTraceability([
          this.deletingItem.id,
          this.$toast
        ])

        this.loadingQrRecords = true
        await this.fetchQrRecords().finally(() => {
          this.loadingQrRecords = false
        })
      },
      // Dialog data
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.showDialog = true
      },
      // Close Delete dialog for delete Record
      dialogClose() {
        this.showDialog = false
      },
      async searchAll() {
        this.options.page = 1

        await this.fetchQrRecords()
      },
      async searchForText() {
        this.options.page = 1
        this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
        this.$store.commit('ui/CLEAR_LOCAL_STORAGE')
        await this.searchText()
      },
      async searchText() {
        this.loadingQrRecords = true
        const keys = ['pageableDTO']
        let filters = _.pick(this.filters, keys)

        filters.companyId = this.profile.company_id
        filters.stringFilter = this.search

        let direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'desc'
            : 'asc'

        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: 'reportDate',
          direction: direction,
        }

        if (this.search && this.search !== '') {
          await this.fetchTraceabilityQRData([this.$toast, filters])
        } else {
          await this.fetchQrRecords(1)
        }

        this.loadingQrRecords = false
      },
      headers() {
        return [
          {
            text: "",
            value: "statusLine",
            sortable: false,
          },
          {
            text: i18n.t("records.fields.status"),
            value: "status",
            val: "status",
            type: 'text'
          },
          {
            text: i18n.t("documents.fields.type"),
            value: "reportType",
            val: "reportType",
            type: "text",
            sortable: false,
          },
          {
            text: i18n.t("records.label.titles.product"),
            value: "product",
            val: "products",
            type: "text",
            sortable: false,
          },
          {
            text: i18n.t("reports.fields.lot"),
            value: "lot",
            val: "lots",
            type: "text",
            sortable: false,
          },
          {
            text: i18n.t("records.fields.date"),
            value: "reportDate",
            type: "date",
            sortable: false,
          },
          {
            text: i18n.t("general.fields.printLimit"),
            value: "printLimit",
            type: "number",
            sortable: false,
            align: this.$store.getters['integration/IS_PRESENT']('QR_SITE') ? 'center' : 'none',
          },
          {
            text: i18n.t("general.fields.printed"),
            value: "printed",
            type: "number",
            sortable: false,
            align: this.$store.getters['integration/IS_PRESENT']('QR_SITE') ? 'center' : 'none',
          },
          {
            text: i18n.t("general.fields.available"),
            value: "availablePrint",
            type: "number",
            sortable: false,
            align: this.$store.getters['integration/IS_PRESENT']('QR_SITE') ? 'center' : 'none',
          },
          {
            text: i18n.t("general.fields.active"),
            value: "active",
            val: "isActive",
            type: "text",
            sortable: false,
          },
          {
            text: i18n.t("general.fields.action"),
            value: "action",
            width: 170,
            sortable: false,
            type: "actions",
            align: "center",
          },
        ];
      },
      async activeOrDesactive(item) {
        this.$set(this.loading, item.id, true);

        setTimeout(async () => {
          await this.activeOrDesactiveQr([this.$toast, {
            id: item.id,
            active: !item.active
          }]).finally(() => {
            this.$set(this.loading, item.id, false);
          })
        }, 1500)
      },
      async print(item) {
        await this.$router.push({ name: 'configReportLabel', params: { item: item } })
      },
      openQrCodeDialog(item) {
        this.qrCodeUrl = item.url
        this.qrCodeDialog = true
      },
      closeQrCodeDialog() {
        this.qrCodeDialog = false
        this.qrCodeUrl = null
      },
      getDateFormat(dateString) {
        return moment(new Date(dateString)).format(this.date.dateFormat)
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      cancelRequest() {
        if (this.source) this.source.cancel("cancelRequest")

        this.message = i18n.t("general.notification.operationCanceled")
        this.source = null
      },
      openFilters() {
        this.type = "traceability-qr"
        this.drawer = true
      },
      // FILTERS
      cleanFilter(chip) {
        let tag = chip.value
        if (this.filters[tag]) {
          if (this.filtersObjects[tag]) {
            if (tag === 'reportType') {
              this.filtersObjects[tag] = null
              this.filters[tag] = null
            } else {
              this.filtersObjects[tag] = []
              this.filters[tag] = []
            }
          }

          if (tag === 'status') {
            this.$set(this.filtersObjects, 'record', {
              status: []
            })
            this.$set(this.filtersObjects, 'record', {
              status: []
            })
            this.filters.status = []
          }

          if (tag === 'isActive') {
            this.filtersObjects.qr.isActive = null
            this.filters[tag] = null
          }

          if (tag === 'products') {
            this.filtersObjects['products_ids'] = []
          }
        }

        if (tag === 'isActive') {
          this.filtersObjects.qr.isActive = null
          this.filters[tag] = null
        }

        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },
      applyFilters(page) {
        this.filters.reportType =
          this.filtersObjects.reportType
            ? this.filtersObjects.reportType
            : null

        this.filters.products =
          this.filtersObjects.products_ids &&
          this.filtersObjects.products_ids.length > 0
            ? this.getByProperty(
                this.filtersObjects.products_ids,
                'product_name'
              )
            : []

        this.filters.lots =
          this.filtersObjects.lots &&
          this.filtersObjects.lots.length > 0
            ? _.map(this.filtersObjects.lots, l => l.name)
            : []


        this.filters.isActive =
          this.filtersObjects.qr.isActive !== null
            ? this.filtersObjects.qr.isActive
            : null

        this.filters.status = this.filtersObjects.record.status &&
        this.filtersObjects.record.status.length > 0
          ? this.filtersObjects.record.status
          : []

        this.fetchQrRecords(page)
      },

      getCantFilters() {
        let cant = 0
        _.forEach(this.filters, (value, key) => {
          if (FILTER_VALUES.includes(key) && value !== null) {

            if (key === 'isActive') {
              cant += typeof value === 'boolean' ? 1 : 0
            } else if (key === 'reportType') {
              cant += value !== null ? 1 : 0
            } else {
              cant += value.length
            }

          }
        })

        this.cantFilters = cant
      },

      filteredChips() {
        let chips = []
        _.forEach(this.filters, (value, key) => {
          if (
            FILTER_VALUES.includes(key) &&
            this.filters[key] !== null &&
            this.filters[key] !== undefined &&
            this.filters[key].length > 0
          ) {
            chips.push({
              value: key,
              name: _.filter(this.headers(), { val: key })[0].text,
            })
          } else {
            if (typeof this.filters.isActive === 'boolean' && key === 'isActive') {
              chips.push({
                value: key,
                name: _.filter(this.headers(), { val: key })[0].text,
              })
            }
          }
        })

        this.filtersChips = chips
      },
      closeFilterDialog(page) {
        this.search = ''

        this.applyFilters(page)
        this.filteredChips()
        this.getCantFilters()
        this.drawer = false
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      async initialize() {
        this.request = false
        this.source = null
        this.profile = JSON.parse(localStorage.getItem("profile"))
        this.$store.commit('ui/FILTERS_ORIGINAL_STATE');
        this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
      },
      // Details
      getDetails(item) {
        this.$router.push({ name: 'traceability-report', params: { item: item } })
      },
      backupPageable() {
        if (this.filters.pageableDTO) {
          this.pageableDTO = _.cloneDeep(this.filters.pageableDTO)
        } else {
          this.filters.pageableDTO = _.cloneDeep(this.pageableDTO)
        }
      },
      async fetchQrRecords(page) {
        this.loadingQrRecords = true;

        if (page) {
          this.options.page = page.no
          this.optionsTable = this.options
        }

        this.backupPageable()

        const keys = [
          "pageableDTO",
          "lots",
          "isActive",
          "reportType",
          "products",
          "stringFilter",
          "status"
        ]

        let filters2 = null
        filters2 = _.pick(this.filters, keys)
        filters2.pageableDTO = this.filters.pageableDTO

        await this.fetchTraceabilityQRData([this.$toast, filters2]).then(
          () => {
            this.searchRecords = false
            this.loadingQrRecords = false;
          }
        )
      },
      // Check permission
      check(permissions) {
        return PermisionService.check(permissions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermisionService.checkAdmin()
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', "")
        this.search = event.currentTarget.value.toString().replaceAll('"', "")
      },
    },
    destroyed() {
      this.request = false
      this.message = i18n.t('general.notification.loadData')
      if (this.source) {
        this.source.cancel('cancelRequest')
        this.source = null
      }

      this.$root.$emit("clear:selected:lots")

      this.$store.commit('general/SET_ORIGINAL_STATE_CONFIG')
      this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
      this.$root.$off('setFiltersQRTraceability')
      this.$root.$off('getResults')
    },
  }
</script>

<style scoped>
  .v-data-table {
    margin: 25px;
  }

  #change p {
    width: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  #change:hover p {
    width: 130px;
    max-width: 100%;
    overflow-x: hidden;
  }

  #change:hover i {
    transform: rotate(200grad);
  }

  .table__head {
    height: 5vh;
    background-color: #f7f7f7;
  }

  .amount__chip {
    border-radius: .4em !important;
  }

  .print-limit__chip {
    border-radius: .4em !important;
    margin-right: 0;
    padding-right: 5px;
  }

  .info__icon {
    background-color: white;
    font-size: 26px !important;
    color: #545454 !important;
  }
  :deep(.v-chip-group .v-slide-group__content) {
    display: flex !important;
    justify-content: flex-start !important;
  }
  :deep(.v-toolbar__content) {
    padding: 0 !important;
  }
</style>
