<template>
  <v-tooltip top>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn v-bind='attrs'
             v-on='isIcon ? on : false'
             small
             text
             :loading='loading'
             :fab='isIcon'
             @click.stop='fetchErrors()'
      >
        <template v-slot:loader>
          <v-progress-circular indeterminate size='14' width='3' :class="{'mr-2' : !isIcon}" />
          <span v-if='!isIcon'>{{ $t('records.titles.errors') }}</span>
        </template>
        <v-icon
          :color='iconColor'
          :left='!isIcon'
        >
          mdi-bug-outline
        </v-icon>
        <span v-if='!isIcon'>{{ $t('records.titles.errors') }}</span>
      </v-btn>
    </template>
    <span>{{ $t('records.titles.errors') }}</span>
  </v-tooltip>
</template>

<script>
  import { mapActions } from 'vuex'
  import LanguajeService from '@/services/LanguajeService'

  export default {
    name: 'DownloadErrorsComponent',

    props: {
      item: {
        type: Object,
        required: true,
      },
      iconColor: {
        type: String,
        required: false,
        default: 'black',
      },
      isIcon: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      loading: false,
    }),

    methods: {
      ...mapActions({
        exportErrors: 'records/exportErrors',
      }),
      async fetchErrors() {
        this.$emit('close:options', true)
        this.loading = true

        await this.exportErrors([this.item.id, LanguajeService.getLenguajeName(), this.$toast]).finally(() => {
          this.$emit('close:options', false)
          this.loading = false
        })
      },
    },
  }
</script>

<style scoped>

</style>